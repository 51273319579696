import TitleState from '../../../title-actions/TitleState';
import MediaInterface from '../MediaInterface';
import { toStaticUrl } from '../../../../../lib/assetMapper';
import { getOptimizedCovers } from '../../../../../lib/getOptimizedCover-ts';

export default class Title extends Backbone.Model {

    get idAttribute() { return 'id'; }

    defaults() {
        return {
            id                          : 0,
            firstCreatorName            : null,
            title                       : 'Title',
            firstCreatorId              : 0,
            formats                     : [],
            isOwned                     : false,
            isHoldable                  : false,
            isRecommendableToLibrary    : false,
            isPreReleaseTitle           : false,
            isAvailable                 : false,
            starRating                  : 0.0,
            userStarRating              : 0,
            starRatingCount             : 0,
            holdsRatio                  : 0,
            holdsCount                  : 0,
            ownedCopies                 : 0,
            availableCopies             : 0,
            luckyDayOwnedCopies         : 0,
            luckyDayAvailableCopies     : 0,
            estimatedReleaseDate        : '',
            availabilityType            : '',
            description                 : '',
            sample                      : {}, // Sample
            publisher                   : {}, // Publisher
            type                        : {id: 'ebook'}, // Type
            covers                      : {}, // Covers
            parentMagazineTitleId       : 0,

            // Custom Lightning Properties
            titleState                  : 'waiting',
            isRecommended               : false,
            wishlistState               : 'wishlistAdd',
            isInHistory                 : false,
            addToAppFormat              : null,
            isOnHold                    : false,
            isReleasedHold              : false,
            isBorrowed                  : false,
            isMostRecentMagazine        : false
        };
    }

    get isPlaceholder() {
        return this.get('placeholder');
    }

    setContext(context) {
        this.context = context;
    }

    constructor(model, options, context) {
        let { patronItems } = options || {};

        super(model, options);

        this.setContext(context);

        this.set(_.extend({}, model, { titleState: this._getState(patronItems) }));
        if (!this.isPlaceholder){
            this.link(window.globalEvents);
        }
    }

    link(bus) {
        // Unlink the old bus if there is one
        if (this.bus) {
            this.stopListening(this.bus, 'title:update');
            this.bus = null;
        }

        // Attach new bus if passed
        if (bus) {
            this.bus = bus;
            this.listenTo(this.bus, 'title:update', (titleModel) => {
                this._applyUpdates(titleModel);
            });
        }

        return this;
    }

    _applyUpdates(titleModel) {
        if (titleModel !== this && titleModel.id === this.id) {
            super.set(titleModel.changedAttributes());
        }
    }

    set() {
        super.set(...arguments);
        this.bus && this.bus.trigger('title:update', this);
    }

    // An example of this can be seen in CollectionHightlight.js:61 (where TitleCollectionView gets new'ed up)
    get contextCollectionId() {
        return this.context && this.context.collectionDefinition
            ? this.context.collectionDefinition.id
            : undefined;
    }

    get titleDetailsUrl() {
        // LHTNG-3018 Reference optional contextCollectionId value when creating a title details link
        let props = { id: this.id };

        if (this.contextCollectionId) {
            props.cid = this.contextCollectionId;
        }

        return window.url(window.routes.mediaId, props);
    }

    get hasLuckyDayCopies () {
        return window.featureManager.isEnabled('LuckyDay') && this.get('luckyDayOwnedCopies') > 0;
    }

    get allCopiesAreLuckyDay(){
        return this.hasLuckyDayCopies && this.get('ownedCopies') === 0;
    }

    get isLuckyDayCheckout() {
        return this.hasAvailableLuckyDayCopies && this.get('availableCopies') === 0 && !this.get('isReleasedHold');
    }

    get canPlaceHold() {
        return this.get('isHoldable') && !this.get('isOnHold') && window.featureManager.isEnabled('content-holds');
    }

    get isAvailable(){
        return this.get('isAvailable') || this.hasAvailableLuckyDayCopies;
    }

    get hasAvailableLuckyDayCopies(){
        return this.hasLuckyDayCopies && this.get('luckyDayAvailableCopies') > 0;
    }

    get isExpired() {
        return this.get('isOwned') && (this.isExpiredSuTitle || this.isExpiredNonSuTitle);
    }

    get isExpiredSuTitle() {
        return this.get('availabilityType') === 'always' && !this.get('isPreReleaseTitle') && !this.get('isAvailable');
    }

    get isExpiredNonSuTitle() {
        return this.get('availabilityType') !== 'always' && !this.get('ownedCopies');
    }

    updatePatronState(patronItems, options = { }) {
        this.set({
            titleState      : this._getState(patronItems),
            wishlistState   : TitleState.isOnWishlist(this.attributes, patronItems) ? 'wishlistRemove' : 'wishlistAdd',
            userStarRating  : TitleState.starRating(this.attributes, patronItems) // 0 if not rated
        }, options);
        const readingHistoryState = TitleState.isOnReadingHistory(this.attributes, patronItems);
        this.set('isInHistory', readingHistoryState, options || {});

        const userStarRating = TitleState.starRating(this.attributes, patronItems);
        this.set('userStarRating', userStarRating, options || {}); // 0 if not rated
    }

    getCover(desiredSize) {
        const covers = _.map(this.get('covers'), (cover) => cover); // maps to collection of covers
        return getOptimizedCovers(covers, desiredSize);
    }

    getCoverSrc(desiredSize) {
        const cover = this.getCover(desiredSize);
        return cover && cover.href || toStaticUrl('img/no-cover_en_US.jpg');
    }

    getCurrentAvailability() {
        return $.ajax({
            url: window.url(window.routes.mediaAvailabilityId, {id: this.id}),
            type: 'GET',
            timeout: 0
        });
    }

    getMissingAttribute(attribute) {
        if (!this._mediaInterface) {
            this._mediaInterface = new MediaInterface();
        }
        return this._mediaInterface.search({titleId: this.get('id')}).then((response) => {
            this.set(attribute, response[attribute]);
            return Promise.resolve();
        });
    }

    availabilityText() {
        let action = this.get('titleState');
        let availabilityText = '';

        switch (action) {
            case 'recommend':
                availabilityText = window.html('availabilityNotOwned');
                break;
            case 'recommended':
                availabilityText = window.html('recommended');
                break;
            case 'unavailable':
                availabilityText = window.html('availabilityUnavailable');
                break;
            case 'nook':
                availabilityText = window.html('availabilityAvailable');
                break;
            case 'borrow' || 'preview':
                availabilityText = window.html('availabilityAvailable');
                break;
            case 'borrowed':
            case 'addToApp':
            case 'listenNow':
            case 'readNow':
                availabilityText = window.html('borrowed');
                break;
            case 'hold':
                if (this.get('isPreReleaseTitle')) {
                    availabilityText = window.html('availabilityComingSoon');
                    break;
                }

                availabilityText = this.get('isPreReleaseTitle') ? window.html('availabilityComingSoon') : window.html('availabilityWaitList');
                break;
            case 'onHold':
                availabilityText = window.html('onHold');
                break;
            case 'waiting':
                availabilityText = window.html('loading');
                break;
        }

        return availabilityText;
    }

    _getState(patronItems) {
        this.setPatronState(patronItems);
        return TitleState.getState(this.attributes, patronItems);
    }

    setPatronState(patronItems) {
        this.set({isOnHold: patronItems && patronItems.onHolds.indexOf(this.id) > -1});
        this.set({isBorrowed: patronItems && patronItems.onCheckouts.indexOf(this.id) > -1});
        this.set({isRecommended: patronItems && patronItems.recommendedToLibrary.indexOf(this.id) > -1});
    }

    sanitizedDesc() {
        let desc = this.get('description').replace(/(<br\s*[\/]?>)|(\r?\n|\r)/gim, ' ');
        return $(`<div>${desc}</div>`).text();
    }

    getTitleAsync(mediaId, resolve, reject) {
        $.ajax({
            url: window.url(window.routes.mediaInfoId, {id: mediaId}),
            type: 'GET',
            timeout: 10000,
            success: (data) => {
                this.set(data);
                this.set('titleState', this._getState(null));
                resolve();
            },
            fail: (err) => {
                reject(err);
            }
        });
    }

    get isMagazine(){
        return this.get('type').id === 'magazine';
    }
}
