import HashManager from '../../components/common/HashManager';
import Title from '../../components/media/models/Title';
import TitleActions from '../../title-actions/TitleActions';
import {recommend} from '../forwardedActions.js';

export default class ProcessForwardedAction {
    constructor(collection, bus, model) {
        let hash = HashManager.get('action')[0];
        this.actionArr = hash ? hash.split('~') : [];
        this.action = this.actionArr[0] || null;
        this.mediaId = this.actionArr[1] || null;
        this.rating = this.actionArr[2] || 0;
        this.model = model || null;
        this.collection = collection.models;
        this.bus = bus;
    }

    // Execute user forwarded action
    executeAction() {
        if (!this.action || !this.mediaId) {
            return;
        }

        if (!this.model ||  (this.model && this.model.get('id') !== this.mediaId)) {
            this.model = _.find(this.collection, (model) => model.id === this.mediaId);
        }

        let titleActions = new TitleActions({bus: this.bus});

        if (this.model) {
            // Title model is on page
            HashManager.remove('action');

            // LHTNG-1685 - Throw conflict modal if title is isOwned on a 'recommend' forwarded action
            if (this.action === 'recommend' && this.model.get('isOwned')) {
                if (this.model.get('titleState') === 'borrow') {
                    this.bus.trigger('title:hold:conflict', { titleModel: this.model });
                } else {
                    this.bus.trigger('title:borrow:conflict', { titleModel: this.model });
                }

                return;
            }

            this.model.set('userStarRating', this.rating);
            titleActions.perform(this.action, this.model);

        } else if (this.action === 'recommend' && $(`a.primary-action[data-media-id="${this.mediaId}"]`).length > 0) {
            // Recommended from the preview row
            // Remove after RTL preview row is refactored
            recommend('', this.action, this.bus);
            HashManager.remove('action');
        } else {
            // Title model is not on page, fetch async
            let title = new Title();
            let promise = new Promise((resolve, reject) => {
                title.getTitleAsync(this.mediaId, resolve, reject);
            });

            promise.then(() => {
                this.model && this.model.set('userStarRating', this.rating);
                titleActions.perform(this.action, title);
                HashManager.remove('action');
            });
        }
    }
}
