class TitleState {

    static getState(media, patronItems) {
        const featureManager = window.featureManager;

        if (featureManager.isEnabled('sampleOnlyMode')){
            return 'preview';
        }

        let mediaId = media.id;

        let isOnHold            = patronItems && patronItems.onHolds.indexOf(mediaId) > -1;
        let isOnCheckouts       = patronItems && patronItems.onCheckouts.indexOf(mediaId) > -1;
        let isOnRecommendations = patronItems && patronItems.recommendedToLibrary.indexOf(mediaId) > -1 || media.isRecommended;
        let isAvailable = media.isAvailable || (featureManager.isEnabled('LuckyDay') &&  media.luckyDayAvailableCopies > 0);

        if (/account\/loans/.test(window.location.href) && media.isRenewable && featureManager.isEnabled('content-holds')) {
            if (isOnHold) {
                return 'requested';
            }
            else if (media.holdsCount <= 0 && media.ownedCopies >= 0 && !(featureManager.isEnabled('LuckyDay') && media.isLuckyDayCheckout)) {
                return 'renew';
            }
            return 'requestAgain';
        }

        if (isOnCheckouts) {
            return 'borrowed';
        }

        if (isOnHold && !isAvailable) {
            return 'onHold';
        }

        if (isOnRecommendations && !isAvailable && !media.isHoldable) {
            return 'recommended';
        }

        if (isAvailable) {
            if (media.type.id === 'periodicals') {
                return 'nook';
            }

            return 'borrow';
        }

        if (!media.isOwned &&
        media.isRecommendableToLibrary &&
        featureManager.isEnabled('recommend-to-library') &&
        !featureManager.isEnabled('rtl-sunset')) {
            return 'recommend';
        }

        if (media.isHoldable && featureManager.isEnabled('content-holds')) {
            return 'hold';
        }

        return 'unavailable';
    }

    static isOnWishlist(media, patronItems) {
        return patronItems && patronItems.onWishlist.indexOf(media.id) > -1;
    }

    static isOnReadingHistory(media, patronItems) {
        return patronItems && patronItems.onReadingHistory.indexOf(media.id) > -1;
    }

    static starRating(media, patronItems) {
        // Returns 0 if not rated
        return patronItems && patronItems.starRatings && patronItems.starRatings[media.id] || 0;
    }
}

export default TitleState;
