import WishListButtonTemplate from './templates/WishListButton.html';

export default class WishListButton extends Backbone.View {

    get contrastClass() {
        return this.contrast === 'default' ? '' : 'contrast-alternate';
    }

    get ariaLabel() {
        const title = this.model.attributes.title;
        return this.model.get('wishlistState') === 'wishlistAdd'
                ? window.html('addTitleToWishList', {title})
                : window.html('removeTitleFromWishList', {title});
    }

    get iconClass() {
        return this.model.get('wishlistState') === 'wishlistAdd' ? 'icon-wish-list' : 'icon-wish-list-added';
    }

    get text() {
        if (this.type === 'icon-only') {
            return '';
        }
        return this.model.get('wishlistState') === 'wishlistAdd'
                        ? window.html('addToWishList')
                        : window.html('removeFromWishList');
    }

    get ga() {
        const isAdd = this.model.get('wishlistState') === 'wishlistAdd';
        if (isAdd) {
            return {
                mediaId: this.model.attributes.id,
                formatType: this.model.attributes.type.id
            };
        }
        return null;
    }

    constructor({type, contrast}) {
        super(...arguments);
        this.type = type || 'icon-only';
        this.contrast = contrast || 'default';

        this.listenTo(this.model, 'change:wishlistState', this.render);
        this.type = type || 'icon-only';
    }

    events() {
        return { 'click': '_handleClick' };
    }

    render() {
        if (!this.el || this.$el.length < 1){
            return; // we have no target to render anything into
        }

        // retain focus on rerender
        const needsRefocus = this.el.querySelector('.WishListButton') === document.activeElement;

        this.$el.empty();

        // LHTNG-1648 - Dont render wishlist icon on recommend* state titles
        if (_(['recommend', 'recommended']).contains(this.model.get('titleState'))) {
            return this;
        }

        const buttonHtml = WishListButtonTemplate({
            ga: this.ga,
            contrastClass: this.contrastClass,
            ariaLabel: this.ariaLabel,
            iconClass: this.iconClass,
            text: this.text,
            iconAnimationClasses: window.OverDrive.isTolinoDevice ? '' : 'animated bounceIn'
        });

        this.$el.append(buttonHtml);

        if (needsRefocus) {
            this.el.querySelector('.WishListButton').focus();
        }

        return this;
    }

    _handleClick() {
        this.model.trigger('title:action', { action: 'wish', model: this.model });
        if (!window.OverDrive.isTolinoDevice) {
            this._animate();
        }
    }

    _animate() {
        this.$el.find('i').removeClass('animated bounceIn bounceOut').addClass('animated bounceOut');
    }
}
