const { featureManager, html, url, routes } = window;

export default ({
    isOwned,
    id,
    rid,
    typeId,
    title,
    alignment,
    sampleHref,
    sampleText,
    historyActionLabel,
    historyActionText,
    historyEnabled,
    gaHistoryAction,
    seriesSearchUrl,
    hasMediaDoFormat,
}) => `
<div class="ContextMenu">
    <div tabindex="0" class="ContextMenu-button js-context-button" data-media-id="${id}"
         data-title="${title}"
         data-dropdown="context-menu-${id}-${rid}"
         data-options="align:${alignment}"
         aria-controls="more-actions-drop-${id}"
         aria-expanded="false"
         aria-label="${html('cover.titleInfo', { title })}"
         role="button">
        <i class="icon icon-more" tabindex="-1"></i>
    </div>

    <ul id="context-menu-${id}-${rid}" class="ContextMenu-drop js-context-drop f-dropdown text-left" data-dropdown-content aria-hidden="false" tabindex="-1" role="list" aria-haspopup="true">
        ${
            (!window.featureManager.isEnabled('hide-unowned-samples') ||
                isOwned) &&
            sampleHref !== ''
                ? `
            <li class="read-sample ContextMenu-item">
                <a data-href="${sampleHref}" data-media-id="${id}" data-title="${title}"
                data-of-dropdown="more-actions-drop-${id}" data-type="${typeId}" media-do-format="${hasMediaDoFormat}"
                tabindex="-1" href="javascript:void(0)" aria-label="${html(
                    'cover.sampleLabel',
                    { sample: sampleText, title }
                )}">
                    ${sampleText}
                </a>
            </li>`
                : ''
        }
        <li class="ContextMenu-item">
            <a tabindex="-1" href="${url(routes.mediaId, {
                id,
            })}" aria-label="${html('viewDetailsAboutTitle', {
    title,
})}">${html('viewDetails')}</a>
        </li>
        ${
            seriesSearchUrl && seriesSearchUrl !== ''
                ? `
            <li class="ContextMenu-item"><a tabindex="-1" href="${seriesSearchUrl}">${html(
                      'viewSeries'
                  )}</a></li>`
                : ''
        }
        ${
            historyEnabled
                ? `<li class="ContextMenu-item js-historyAction" data-ga-action="${gaHistoryAction}" data-media-id="${id}"><a tabindex="-1" role="button" aria-label="${historyActionLabel}">${historyActionText}</a></li>`
                : ``
        }
        <li class="ContextMenu-item"><a tabindex="-1" href="${url(
            routes.mediaRelatedId,
            { id }
        )}" aria-label="${html('viewRelatedToTitle', { title })}">${html(
    'youMayAlsoLike'
)}</a></li>
    </ul>
</div>`;
