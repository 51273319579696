import Title from '../components/media/models/Title';
import TitleActions from '../title-actions/TitleActions';

// process instant forward actions (refactored)
const recommend = function(qs, action, bus) {
    if (action) {
        const mediaId = action.split('~')[1];
        const $actionLink = $(`.js-recommend[data-media-id="${mediaId}"]`);
        if ($actionLink.length > 0) {
            // remove the fragment
            history.replaceState(null, null, window.location.pathname + qs);

            // have to do a js click instead of jQuery on <a href>?
            $actionLink[0].click();
        } else {
            // Remove action from url
            history.replaceState(null, null, window.location.pathname + qs);
            let title = new Title();
            let promise = new Promise((resolve, reject) => {
                title.getTitleAsync(mediaId, resolve, reject);
            });

            promise.then(() => {
                let titleAction = new TitleActions({bus});
                titleAction.perform('recommend', title);
            });
        }
    }
};

export {
    recommend
};
