import { Modal } from  '../common/modal';
import GetACardTemplate from './GetACardModal.html';
import NeedMoblieNumberPopover  from   '../../components/need_mobile_number_popover/NeedMoblieNumberPopover';

export default class GetACardModal extends Modal {
    constructor() {
        var classes = ['GetACardModal'];
        if(window.featureManager.isEnabled('OzoneAuthentication')){
            classes.push('extraPadding');
        }

        let content = GetACardTemplate();
        let modalContents = {
            classes : classes,
            title: window.html('getCard.needCard'),
            hiddenTitle: true,
            content
        };

        if(!window.featureManager.isEnabled('gac-cookie-on-dismiss')) {
            Cookies.set('gacPromoDismissed', 1, {httpOnly: false});
        }
        
        super(modalContents);

        if(window.featureManager.isEnabled('OzoneAuthentication')){
            this.numVerificationToolTip = new NeedMoblieNumberPopover(this.$el.find('#needMyMobileNum')).render();
        }

        this.$el.find('.button').on('click', () => {
            this._setCookie();
        });
    }

    close() {
        this._setCookie();
        super.close();
    }

    _setCookie() {
        if(window.featureManager.isEnabled('gac-cookie-on-dismiss')) {
            Cookies.set('gacPromoDismissed', 1, {httpOnly: false});
        }
    }

    close() {
        Cookies.set('gacPromoDismissed', 1, {httpOnly: false});
        super.close();
    }
}
