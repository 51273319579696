import ContextMenuTemplate from './templates/ContextMenu.html';
const { featureManager, url, routes, OverDrive, text, html } = window;

export default class ContextMenu extends Backbone.View {
    template = ContextMenuTemplate;

    constructor() {
        super(...arguments);

        this.rid = Math.random().toString(36).substring(7);

        this.listenTo(this.model, 'change:isInHistory', () => this.render());

        this.render();
    }

    events() {
        let events = {
            'click .js-historyAction': '_handleClick',
            'keydown .js-historyAction': '_handleKeyHistory',
            'keydown .js-context-button': 'toggle',
        };

        if (featureManager.isEnabled('cleanupContextMenuToggle')) {
            events['keyup .js-context-drop'] = '_handleKeyUpContainer';
        }
        return events;
    }

    _handleKeyUpContainer(e) {
        if (e.keyCode === 27 /*ESC*/) {
            this.close();
        }
    }

    _handleClick() {
        this.model.trigger('title:action', {
            action: 'history',
            model: this.model,
        });
    }

    _handleKeyHistory(e) {
        if (!window.featureManager.isEnabled('cleanupContextMenuToggle')) {
            e.stopImmediatePropagation();
        }

        if (e.keyCode === 13 || e.keyCode === 32) {
            if (window.featureManager.isEnabled('cleanupContextMenuToggle')) {
                e.stopImmediatePropagation();
            }
            this.model.trigger('title:action', {
                action: 'history',
                model: this.model,
            });
        }
    }

    get viewModel() {
        let seriesSearchUrl = '';
        if (
            !(
                this.model.isMagazine &&
                featureManager.isEnabled('magazine-rollup')
            )
        ) {
            if (
                featureManager.isEnabled('enhancedSeries') &&
                this.model.get('detailedSeries')
            ) {
                seriesSearchUrl = url(routes.searchByType, {
                    type: 'seriesId',
                    query: this.model.get('detailedSeries').seriesId,
                    sortBy: 'readingOrder',
                });
            } else if (this.model.get('series')) {
                seriesSearchUrl = url(routes.searchByType, {
                    type: 'series',
                    query: this.model.get('series'),
                    sortBy: 'newlyadded',
                });
            }
        }

        var hasMediaDoFormat = false;
        this.model.get('formats').forEach((format) => {
            if (format.id === 'ebook-media-do') {
                hasMediaDoFormat = true;
            }
        });

        return {
            isOwned: this.model.get('isOwned'),
            id: this.model.id,
            rid: this.rid,
            title: this.model.get('title'),
            typeId: this.model.get('type').id,
            sampleHref: this.model.get('sample').href || '',
            sampleText: this._getSampleText(),
            alignment: 'bottom',
            historyActionLabel: this._getHistoryActionLabel(),
            historyActionText: this._getHistoryActionText(),
            historyEnabled:
                OverDrive.showHistoryFeature &&
                !this.model.get('isPreReleaseTitle'),
            gaHistoryAction: this.model.get('isInHistory')
                ? 'history-remove'
                : 'history-add',
            seriesSearchUrl,
            hasMediaDoFormat,
        };
    }

    _getSampleText() {
        const type = this.model.get('type').id;

        if (type === 'audiobook' || type === 'music') {
            return text('sampleListen');
        } else if (type === 'video') {
            return text('sampleWatch');
        }

        return text('sampleRead');
    }

    _getHistoryActionLabel() {
        const title = this.model.get('title');
        if (this.model.get('isInHistory')) {
            return html('readingHistory.removeTitle', { title });
        } else {
            return html('readingHistory.addTitle', { title });
        }
    }

    _getHistoryActionText() {
        if (this.model.get('isInHistory')) {
            return html('readingHistory.remove');
        } else {
            return html('readingHistory.add');
        }
    }

    render() {
        this.$el.empty().append(this.template(this.viewModel));

        return this;
    }

    isKeyboardSelectEvent = (e) =>
        (e.which === 13 || e.which === 32) && (e.preventDefault() || true);

    close() {
        window.Foundation.libs.dropdown.close(
            this.$el.find('.js-context-drop')
        );
    }

    toggle(e) {
        if (this.isKeyboardSelectEvent(e)) {
            window.Foundation.libs.dropdown.toggle(
                this.$el.find('.js-context-button')
            );
        }
    }
}
