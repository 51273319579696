const Backbone = window.Backbone;

const TitleHeaderBar = Backbone.View.extend({
    initialize() {
        this.listenTo(this.model, 'change', this.render);

        this.$titleHeaderBar = $('<div />');

        this.render();
    },

    render() {
        const a = this.returnState();

        this.$titleHeaderBar = $('<div />', a.container).append($('<a />', a.link));
        this.$el.html(this.$titleHeaderBar);
        this.$el.attr('aria-hidden', 'true');

        return this;
    },

    returnState() {
        let banner = {
            container: {
                classes: ['title-header-bar', 'u-allCaps']
            },
            link: {
                text: '',
                href: this.model.titleDetailsUrl,
                tabindex: -1
            }
        };

        this.action = this.model.get('titleState');

        switch (this.action) {
            case 'recommend':
                banner.container.classes.push('is-recommend not-owned-title');
                banner.link.text = window.html('availabilityNotOwned');
                break;
            case 'recommended':
                banner.container.classes.push('is-recommended not-owned-title');
                banner.link.text = window.html('recommended');
                break;
            case 'unavailable':
                banner.container.classes.push('is-unavailable unavailable-title');
                banner.link.text = window.html('availabilityUnavailable');
                break;
            case 'nook':
                banner.container.classes.push('is-nook available-title secondary-background');
                banner.link.text = window.html('availabilityAvailable');
                break;
            case 'borrow':
                banner.container.classes.push('available-title secondary-background is-borrow');
                banner.link.text = window.html('availabilityAvailable');
                break;
            case 'borrowed':
            case 'addToApp':
            case 'listenNow':
            case 'readNow':
                banner.container.classes.push('is-borrowed available-title secondary-background');
                banner.link.text = window.html('borrowed');
                break;
            case 'hold':
                banner.container.classes.push('is-hold unavailable-title');
                banner.link.text = this.model.get('isPreReleaseTitle') ? window.html('availabilityComingSoon') : window.text('availabilityWaitList');
                break;
            case 'onHold':
                banner.container.classes.push('is-onHold unavailable-title');
                banner.link.text = window.html('onHold');
                break;
            case 'waiting':
                banner.container.classes = this.$titleHeaderBar.attr('class').split(' ');
                banner.link.text = this.$titleHeaderBar.find('a:first').text();
                break;
        }

        banner.container.class = banner.container.classes.join(' ');

        return banner;
    }
});

export default TitleHeaderBar;
